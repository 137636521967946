<template>
    <WitModal
        v-model="showModal"
        title="Good Job!"
        variant="primary"
        customClass="automatic-workflow-modal"
        size="md"
        @input="emitInput"
    >
        <div class="automatic-workflow-modal__text">
            <strong>This resource has been put on a schedule</strong>
            <ul>
                <li>
                    Your data will be updated <template v-if="friendlyCron">{{ friendlyCron }}</template
                    ><template v-else>every hour</template>
                </li>
                <li v-if="historical">
                    Historical data is being downloaded in the background. It may take up to 24 hours.
                </li>
            </ul>
        </div>
        <b-button variant="primary" size="md" @click="close">Ok</b-button>
    </WitModal>
</template>

<script>
import cronstrue from 'cronstrue'
import WitModal from './WitModal.vue'

export default {
    components: {
        WitModal,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        historical: {
            type: Boolean,
            default: false,
        },
        customSchedule: {
            type: [String, null],
            default: null,
        },
    },
    watch: {
        value() {
            this.showModal = this.value
        },
    },
    created() {
        this.showModal = this.value
    },
    data() {
        return {
            showModal: false,
        }
    },
    computed: {
        friendlyCron() {
            if (this.customSchedule) {
                const string = cronstrue.toString(this.customSchedule, {locale: 'en'})
                if (string.length) {
                    return `${string[0].toLowerCase()}${string.slice(1)}`
                }
            }
        },
    },
    methods: {
        close() {
            this.showModal = false
            this.emitInput()
        },
        emitInput() {
            this.$emit('input', this.showModal)
        },
    },
}
</script>

<style lang="scss">
.automatic-workflow-modal {
    &__text {
        text-align: left;
    }
}
</style>
